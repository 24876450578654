// File: src/services/pushNotificationService.js

import { base, appDatabasePrimaryFunctions } from '../../base';
import {
    validUrl,
    convertDateToUtc,
    convertTimeStampToHumanReadable,
    Toast,
    ErrorMessage
} from '../utils/HelpfulFunction';
// ^^^ Adjust the paths as necessary based on your actual file structure

/**
 * These environment variables store your Batch API credentials & IDs.
 * Ensure they're set in your .env or .env.production files.
 */
const BATCH_API_KEY = process.env.REACT_APP_BATCH_API_KEY;
const IOS_BATCH_ID = process.env.REACT_APP_IOS_BATCH_ID;
const ANDROID_BATCH_ID = process.env.REACT_APP_ANDROID_BATCH_ID;

/**
 * ----------------------------------------------------------------------------------------
 * 1) Send Immediate Notification to an Array of Tokens
 * ----------------------------------------------------------------------------------------
 *
 * This function handles sending an immediate (non-scheduled) push notification to
 * an array of device tokens. You can call this from anywhere you have user tokens.
 */
export async function sendImmediateNotificationToTokens(tokens, title, message, link = null) {
    try {
        if (!BATCH_API_KEY || !IOS_BATCH_ID || !ANDROID_BATCH_ID) {
            throw new Error('Batch environment variables are missing.');
        }

        // For simplicity, let's assume iOS tokens and Android tokens are in the same array
        // In practice, you might want to separate them by device type if you need distinct logic.
        const urlToSend = `https://api.batch.com/1.0/${IOS_BATCH_ID}/transactional/send`;

        // Build the JSON payload
        const payload = {
            group_id: 'Targeted',
            recipients: {
                tokens,
            },
            sandbox: process.env.NODE_ENV !== 'production', // Sandbox for dev/test
            message: {
                body: message,
            }
        };

        // Optional title
        if (title && title.trim()) {
            payload.message.title = title;
        }

        // Optional link
        if (link && validUrl(link)) {
            payload.deeplink = link;
        }

        // Send the request to Batch
        const response = await fetch(urlToSend, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Authorization': BATCH_API_KEY
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`Batch returned a bad response (${response.status}).`);
        }

        // If you want to show a UI success message:
        await Toast.fire({ title: 'Notification Successfully Sent!' });

    } catch (error) {
        console.error('Error sending immediate notification:', error);
        await ErrorMessage.fire({
            title: 'Error',
            text: error.message || 'Could not send push notifications.'
        });
    }
}

/**
 * ----------------------------------------------------------------------------------------
 * 2) Create or Schedule a Campaign (similar to "sendNotification" in your code)
 * ----------------------------------------------------------------------------------------
 *
 * In your original code, you had a function that either sends a push NOW or schedules one.
 * Below is a combined approach. We'll handle both iOS & Android campaign creation if `schedulePush` is true.
 */
export async function sendOrScheduleCampaign({
                                                 notificationTitle,
                                                 notificationBody,
                                                 notificationLink,
                                                 schedulePush,
                                                 notificationSendTime,
                                                 overallPushId = null,
                                             }) {
    try {
        if (!BATCH_API_KEY || !IOS_BATCH_ID || !ANDROID_BATCH_ID) {
            throw new Error('Batch environment variables are missing. Please contact Sqwad or set up your env file.');
        }

        if (!notificationBody || !notificationBody.trim()) {
            throw new Error('Notification must have a message body.');
        }

        // If it's not scheduled, we send immediate notifications to tokens. (You had logic in your code that fetched tokens from 'batchId' in Firebase, etc.)
        // If it is scheduled, we create a campaign for iOS and a campaign for Android targeting "all users."

        // If scheduling, ensure future time
        let pushTime = null;
        let milliTime = new Date().getTime();
        if (schedulePush && notificationSendTime) {
            if (notificationSendTime.getTime() <= Date.now()) {
                throw new Error('Scheduled time is now or in the past.');
            }
            pushTime = convertDateToUtc(notificationSendTime).toISOString().split(".")[0];
            milliTime = notificationSendTime.getTime();
        }

        // If no 'overallPushId' is provided, generate one:
        if (!overallPushId) {
            overallPushId = appDatabasePrimaryFunctions.ref().push().key;
        }

        if (schedulePush) {
            // Create iOS + Android campaigns
            // They require different endpoints
            const campaignName = `Campaign - ${overallPushId} - ${process.env.REACT_APP_FIREBASE_PROJECT_ID}`;

            const iosCreateUrl = `https://api.batch.com/1.1/${IOS_BATCH_ID}/campaigns/create`;
            const androidCreateUrl = `https://api.batch.com/1.1/${ANDROID_BATCH_ID}/campaigns/create`;

            const bodyData = {
                name: campaignName,
                live: process.env.NODE_ENV === 'production',
                push_time: pushTime || 'now',
                messages: [
                    {
                        language: 'en',
                        body: notificationBody,
                    }
                ]
            };

            if (notificationTitle && notificationTitle.trim()) {
                bodyData.messages[0].title = notificationTitle;
            }
            if (notificationLink && validUrl(notificationLink)) {
                bodyData.deeplink = notificationLink;
            }

            // We'll create iOS and Android campaigns in parallel
            const [iosResponse, androidResponse] = await Promise.all([
                fetch(iosCreateUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': BATCH_API_KEY
                    },
                    body: JSON.stringify(bodyData)
                }),
                fetch(androidCreateUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': BATCH_API_KEY
                    },
                    body: JSON.stringify(bodyData)
                })
            ]);

            const iosJson = iosResponse.ok ? await iosResponse.json() : null;
            const androidJson = androidResponse.ok ? await androidResponse.json() : null;

            // Save to Firebase
            if (iosJson && iosJson.campaign_token) {
                await saveScheduledPushToFirebase({
                    campaignToken: iosJson.campaign_token,
                    messages: bodyData.messages,
                    pushTime: pushTime,
                    milliTime,
                    name: bodyData.name,
                    deeplink: bodyData.deeplink || '',
                    type: 'ios',
                    overallPushId
                });
            }
            if (androidJson && androidJson.campaign_token) {
                await saveScheduledPushToFirebase({
                    campaignToken: androidJson.campaign_token,
                    messages: bodyData.messages,
                    pushTime: pushTime,
                    milliTime,
                    name: bodyData.name,
                    deeplink: bodyData.deeplink || '',
                    type: 'android',
                    overallPushId
                });
            }

            await Toast.fire({
                title: 'Notification Successfully Scheduled!'
            });

        } else {
            // NO scheduling => immediate push to all tokens in your DB
            // In your code, you used something like:
            //   const results = await base.fetch('batchId', { asArray: true });
            // Then you chunked them out, etc.
            // We'll do a simplified version here:

            const allUsers = await base.fetch('batchId', {
                context: this,
                asArray: true
            });

            if (!allUsers || allUsers.length === 0) {
                throw new Error('No recipients found (no users).');
            }

            // Collect all tokens
            const tokens = new Set();
            for (let i = 0; i < allUsers.length; i++) {
                const pushId = allUsers[i].pushToken;
                if (pushId) {
                    tokens.add(pushId);
                }
            }

            if (tokens.size === 0) {
                throw new Error('No valid tokens found among users.');
            }

            // Then do a single immediate push call (for iOS or Android, or both)
            // You had complicated logic about iOS vs Android. For simplicity:
            await sendImmediateNotificationToTokens([...tokens], notificationTitle, notificationBody, notificationLink);
            // Show success message if you want:
            // The sendImmediateNotificationToTokens function already does Toast, so we might skip it here or repeat:
            // await Toast.fire({ title: "Notifications sent to all users!" });
        }
        return true; // success

    } catch (error) {
        console.error('Error in sendOrScheduleCampaign:', error);
        await ErrorMessage.fire({
            title: 'Hold On!',
            text: error.message
        });
        return false;
    }
}

/**
 * Helper: save data in Firebase (similar to your "saveDataInFirebase" method).
 */
export async function saveScheduledPushToFirebase({
                                                      campaignToken,
                                                      messages,
                                                      pushTime,
                                                      milliTime,
                                                      name,
                                                      deeplink,
                                                      type,
                                                      overallPushId
                                                  }) {
    const postObject = {
        campaignToken,
        messages,
        pushTime,
        milliTime,
        name,
        deepLink: deeplink,
        type,
        overallPushId
    };
    try {
        await base.post(`scheduledPushes/${campaignToken}`, { data: postObject });
    } catch (err) {
        console.error('Error saving scheduled push to Firebase:', err);
    }
}

/**
 * ----------------------------------------------------------------------------------------
 * 3) Delete All Campaigns by Overall Push ID
 * ----------------------------------------------------------------------------------------
 *
 * This is similar to your `deleteAllCampaignsByOverallPushId` method.
 * It:
 *   1) Fetches all campaigns in Firebase with that overallPushId
 *   2) Calls Batch API to delete them
 *   3) Removes them from Firebase
 */
export async function deleteAllCampaignsByOverallPushId(overallPushId) {
    try {
        // 1) Fetch from Firebase
        const snapshot = await base.fetch('scheduledPushes', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'overallPushId',
                equalTo: overallPushId
            }
        });

        // 2) For each campaign in snapshot, call the Batch "delete" endpoint
        const deletePromises = snapshot.map(async (campaign) => {
            let urlToDelete;
            if (campaign.type === 'ios') {
                urlToDelete = `https://api.batch.com/1.1/${IOS_BATCH_ID}/campaigns/delete/${campaign.campaignToken}`;
            } else if (campaign.type === 'android') {
                urlToDelete = `https://api.batch.com/1.1/${ANDROID_BATCH_ID}/campaigns/delete/${campaign.campaignToken}`;
            } else {
                return false;
            }

            const response = await fetch(urlToDelete, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': BATCH_API_KEY
                }
            });

            // 3) If success, remove from Firebase
            if (response.ok) {
                await base.remove(`scheduledPushes/${campaign.campaignToken}`);
                return true;
            }
            return false;
        });

        const results = await Promise.all(deletePromises);
        const someSucceeded = results.includes(true);

        if (someSucceeded) {
            await Toast.fire({ title: 'Successfully Deleted All Matching Campaigns' });
        } else {
            await ErrorMessage.fire({ title: 'Failed To Delete Some (Or All) Campaigns' });
        }

    } catch (err) {
        console.error('Error deleting campaigns by overallPushId:', err);
        await ErrorMessage.fire({ title: 'Error', text: err.message });
    }
}

/**
 * ----------------------------------------------------------------------------------------
 * 4) Edit (Update) an existing scheduled campaign (similar to your `editNotification`)
 * ----------------------------------------------------------------------------------------
 *
 * This will:
 *   1) Fetch all scheduled pushes by overallPushId
 *   2) Update each campaign with the new time/body/title/link
 *   3) Update Firebase afterwards
 */
export async function editScheduledCampaign({
                                                overallPushId,
                                                newTitle,
                                                newBody,
                                                newLink,
                                                newDateTime
                                            }) {
    try {
        // 1) Fetch from Firebase
        const scheduledPushesArray = await base.fetch('scheduledPushes', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'overallPushId',
                equalTo: overallPushId
            }
        });

        // 2) For each campaign, build updated request payload and call "update"
        const pushTime = convertDateToUtc(newDateTime).toISOString().split(".")[0];
        const milliTime = newDateTime.getTime();

        const updatePromises = scheduledPushesArray.map(async (scheduledPush) => {
            let batchApiId = null;
            if (scheduledPush.type === 'ios') {
                batchApiId = IOS_BATCH_ID;
            } else if (scheduledPush.type === 'android') {
                batchApiId = ANDROID_BATCH_ID;
            } else {
                return false;
            }

            const urlToUpdate = `https://api.batch.com/1.1/${batchApiId}/campaigns/update/${scheduledPush.campaignToken}`;

            const updateData = {
                name: scheduledPush.name,
                live: process.env.NODE_ENV === 'production',
                push_time: pushTime,
                messages: [
                    {
                        language: 'en',
                        body: newBody
                    }
                ]
            };

            if (newTitle && newTitle.trim()) {
                updateData.messages[0].title = newTitle;
            }
            if (newLink && validUrl(newLink)) {
                updateData.deeplink = newLink;
            }

            const res = await fetch(urlToUpdate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': BATCH_API_KEY
                },
                body: JSON.stringify(updateData)
            });

            if (res.ok) {
                // 3) Update Firebase
                await saveScheduledPushToFirebase({
                    campaignToken: scheduledPush.campaignToken,
                    messages: updateData.messages,
                    pushTime: updateData.push_time,
                    milliTime,
                    name: updateData.name,
                    deeplink: updateData.deeplink || '',
                    type: scheduledPush.type,
                    overallPushId
                });
                return true;
            }
            return false;
        });

        const results = await Promise.all(updatePromises);
        const someSucceeded = results.includes(true);

        if (someSucceeded) {
            await Toast.fire({ title: 'Notification Successfully Edited!' });
        } else {
            await ErrorMessage.fire({ title: 'Failed to Edit Some (or All) Notifications' });
        }

    } catch (err) {
        console.error('Error editing scheduled campaigns:', err);
        await ErrorMessage.fire({
            title: 'Error',
            text: err.message
        });
    }
}

/**
 * ----------------------------------------------------------------------------------------
 * 5) Optional: If you have separate "Get Campaign Notifications" or "Get Stats" logic,
 *    you can also move them here.
 * ----------------------------------------------------------------------------------------
 */

/**
 * Example: get all scheduled pushes in the future from Firebase
 */
export async function getAllFutureScheduledPushes() {
    try {
        const now = new Date().getTime();
        const scheduledPushesSnapshot = await appDatabasePrimaryFunctions
            .ref('scheduledPushes')
            .orderByChild('milliTime')
            .startAfter(now)
            .limitToFirst(100)
            .once('value');

        const newDetailsObject = {};
        scheduledPushesSnapshot.forEach((snap) => {
            const details = snap.val();
            if (!newDetailsObject[details.overallPushId]) {
                newDetailsObject[details.overallPushId] = details;
                let pushTime = details.pushTime || details.push_time;
                newDetailsObject[details.overallPushId].push_time_in_mil = new Date(pushTime).getTime();
                if (details.type === 'android') {
                    newDetailsObject[details.overallPushId].android_campaign_token = details.campaign_token || details.campaignToken;
                } else {
                    newDetailsObject[details.overallPushId].ios_campaign_token = details.campaign_token || details.campaignToken;
                }
            } else {
                // If we already have an entry, just add the iOS or Android token
                if (details.type === 'android') {
                    newDetailsObject[details.overallPushId].android_campaign_token = details.campaign_token || details.campaignToken;
                } else if (details.type === 'ios') {
                    newDetailsObject[details.overallPushId].ios_campaign_token = details.campaign_token || details.campaignToken;
                }
            }
        });
        // Convert to array
        return Object.values(newDetailsObject);
    } catch (e) {
        console.error(e);
        return [];
    }
}

export async function getPushTokenForUser(uid) {

}
