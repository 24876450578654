// import React, {Component} from 'react';
// import SideMenu from '../admin/SideMenu';
// import TopMenu from '../admin/TopBar';
// import {appDatabasePrimaryFunctions} from '../../base';
// import {convertTimeStampToHumanReadable, getURLParameter} from "../utils/HelpfulFunction";
// import {Toast, ErrorMessage, WarningMessage} from '../utils/HelpfulFunction';
// import {Navigate} from "react-router-dom";
//
// class UserInfo extends Component {
//     constructor(props) {
//         super(props);
//         const uid = getURLParameter("uid");
//         const playerPickEnabled = process.env.REACT_APP_HAS_PLAYER_PICK === "true";
//         const checkInEnabled = process.env.REACT_APP_HAS_CHECK_IN === "true";
//         const prizesEnabled = process.env.REACT_APP_HAS_PRIZES === "true";
//         const badgesEnabled = process.env.REACT_APP_HAS_BADGES === "true";
//         this.state = {
//             uid: uid,
//             loading: true,
//             badgesEnabled,
//             prizesEnabled,
//             checkInEnabled,
//             playerPickEnabled
//         };
//         this.handleChange = this.handleChange.bind(this);
//     }
//
//     async componentDidMount() {
//         this.loadUserData();
//         const getAdminToken = await this.props.userData.getIdToken();
//         this.setState({
//             token: getAdminToken
//         })
//     }
//
//     async loadUserData(){
//         const uid = this.state.uid;
//         const uidToUsernameRef = await appDatabasePrimaryFunctions.ref(`uidToUsername/${uid}`).once('value');
//         const uidToUsername = uidToUsernameRef.val() || {};
//         const userName = uidToUsername.userName;
//         let promises = [];
//         let definitionsOfPromises = [];
//         let promisesRun;
//         if(userName){
//             const userRef = appDatabasePrimaryFunctions.ref(`users/${uid}`).once('value');
//             promises.push(userRef);
//             definitionsOfPromises.push('users')
//             const userLevelRef = appDatabasePrimaryFunctions.ref(`userLevel/${uid}`).once('value');
//             const userPointsRef = appDatabasePrimaryFunctions.ref(`userPoints/${uid}`).once('value');
//             if(this.state.badgesEnabled || this.state.checkInEnabled || this.state.playerPickEnabled){
//                 promises.push(userLevelRef, userPointsRef)
//                 definitionsOfPromises.push('level', 'points')
//             }
//             const userHistoryRef = appDatabasePrimaryFunctions.ref(`userHistory/${userName}`).once('value');
//             if(this.state.checkInEnabled){
//                 promises.push(userHistoryRef);
//                 definitionsOfPromises.push('checkIns')
//             }
//             const uniqueRewardUrl = process.env.REACT_APP_REWARD_URL + `redeemed_coupons/user/${uid}`;
//             if(this.state.prizesEnabled){
//                 promises.push(uniqueRewardUrl);
//                 definitionsOfPromises.push('rewards')
//             }
//             const userBadgesRef = appDatabasePrimaryFunctions.ref(`userBadges/${uid}`).once('value');
//             if(this.state.badgesEnabled){
//                 promises.push(userBadgesRef)
//                 definitionsOfPromises.push('badges')
//             }
//             const couponEmailRef = appDatabasePrimaryFunctions.ref(`couponEmail/${uid}`).once('value');
//             const userNameToEmailRef = appDatabasePrimaryFunctions.ref(`usernameToEmail/${userName}`).once('value');
//             promises.push(couponEmailRef, userNameToEmailRef);
//             definitionsOfPromises.push('couponEmail', 'userNameToEmail');
//             promisesRun = await Promise.all(promises);
//         } else {
//             this.setState({
//                 loading: false,
//                 redirect: true
//             })
//             return;
//         }
//         let user = {};
//         let userLevel = {};
//         let userPoints = {};
//         let userRewards = {};
//         let userBadges = {};
//         let couponEmail = "";
//         let userNameToEmail = {};
//         let userHistory = {};
//         for(const i in promisesRun){
//             let promise = promisesRun[i];
//             let promiseName = definitionsOfPromises[i];
//             if(promiseName === "users"){
//                 user = promise.val() || {};
//             } else if(promiseName === "level"){
//                 userLevel = promise.val() || {};
//             } else if(promiseName === "points"){
//                 userPoints = promise.val() || {};
//             } else if(promiseName === "checkIns"){
//                 userHistory = promise.val() || {};
//             } else if(promiseName === "rewards"){
//                 userRewards = promiseName || {};
//             } else if(promiseName === "badges"){
//                 userBadges = promise.val() || {};
//             } else if(promiseName === "couponEmail"){
//                 couponEmail = promise.val() || "";
//             } else if(promiseName === "userNameToEmail"){
//                 userNameToEmail = promise.val() || {};
//             } else {
//                 console.log("NOT FOUND")
//             }
//         }
//         const rewardsRedeemedCount = userRewards.data ? userRewards.data.length : 0;
//         const email = user.email || userNameToEmail.email ||couponEmail || "";
//         const signUpTime = user.signUpTime;
//         const totalPoints = userLevel.totalPoints || 0;
//         const currentPoints = userPoints.points || 0;
//         const checkIns = userHistory.checkIns || {};
//         const checkInsCount = Object.keys(checkIns).length;
//         const numberOfBadges = Object.keys(userBadges).length;
//         this.setState({
//             loading: false,
//             checkInsCount: checkInsCount,
//             currentPoints: currentPoints,
//             totalPoints: totalPoints,
//             signUpTime: signUpTime,
//             email: email,
//             userName: userName,
//             rewardsRedeemedCount: rewardsRedeemedCount,
//             numberOfBadges: numberOfBadges
//         })
//     }
//
//     handleChange (evt) {
//         let target = evt.target;
//         let value = target.type === 'checkbox' ? target.checked : target.value;
//         this.setState({ [evt.target.name]: value });
//     }
//
//     async deleteUser(){
//         const result = await WarningMessage.fire({
//             title: 'STOP!',
//             text: 'Are you sure you want to do this?  This will erase all information about this user.  If you do this the data will be IRRETRIEVABLE.',
//             confirmButtonText: 'YES I WANT TO PERMANENTLY DELETE THIS DATA'
//         })
//         if(!result || !result.value) return;
//         const userResponse = {};
//         userResponse['uidToDelete'] = this.state.uid;
//         let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/deleteUser`;
//         if(process.env.NODE_ENV === "development"){
//             url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/deleteUser`;
//         }
//         this.setState({loading:true})
//         fetch(url, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization':`Bearer ${this.state.token}`
//             },
//             body: JSON.stringify(userResponse),
//         }).then(res => {
//             this.setState({loading:false})
//             const vm = this;
//             Promise.resolve(res.json()).then(function (value) {
//                 if(!value || value.message === "Invalid Permissions") {
//                     ErrorMessage.fire({
//                         title: 'Oh uh!',
//                         text: 'Auth or request error; logout and log back in',
//                     })
//                     return;
//                 } else if(value.message === "auth/user-not-found - There is no user record corresponding to the provided identifier.") {
//                     ErrorMessage.fire({
//                         title: 'Oh uh!',
//                         text: 'User not found, check your internet connection and try again.  If the problem continues reach out to Sqwad.',
//                     })
//                     return;
//                 }
//                 vm.setState({
//                     redirect: true
//                 })
//                 Toast.fire({
//                     title: 'User Deleted'
//                 })
//             })
//         }).catch(err => {
//             ErrorMessage.fire({
//                 title: 'Oh uh!',
//                 text: 'Auth or request error; logout and log back in',
//             })
//             this.setState({loading: false});
//         })
//     }
//
//     render() {
//         const {loading, email="", checkInsCount=0, currentPoints=0, totalPoints=0, signUpTime=0, userName="", rewardsRedeemedCount=0, numberOfBadges=0, redirect=false, playerPickEnabled=false, checkInEnabled=false,prizesEnabled=false,badgesEnabled=false} = this.state;
//         if(redirect === true){
//             return <Navigate to="/usermanagement" />
//         }
//         return (
//           <div className="admin-wrapper">
//             <div className="loading-screen" style={{display: loading ? 'block' : 'none' }}/>
//             <SideMenu/>
//             <TopMenu/>
//             <div className="admin-main-panel">
//                 <div className="container" style={{color: "black", padding:20}}>
//                     <div className="row" style={{margin: 20}}>
//                         <div className="col" style={{textAlign:"center"}}>
//                             <p style={{fontSize: 40}}>User Info</p>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col">
//                             <p>
//                                 Username: {userName}
//                             </p>
//                             <p>
//                                 Email: {email || "None Found"}
//                             </p>
//                             <p>
//                                 Signed Up: {signUpTime ? convertTimeStampToHumanReadable(signUpTime) : "None Detected"}
//                             </p>
//                         </div>
//                     </div>
//                     <div className="row">
//                         {checkInEnabled &&
//                             <div className="col-4">
//                                 <p>
//                                     Check Ins: {checkInsCount}
//                                 </p>
//                             </div>
//                         }
//                         {(playerPickEnabled || checkInEnabled || badgesEnabled) &&
//                             <div className="col-4">
//                                 <p>
//                                     Total Points: {totalPoints}
//                                 </p>
//                             </div>
//                         }
//                         {(playerPickEnabled || checkInEnabled || badgesEnabled) &&
//                             <div className="col-4">
//                                 <p>
//                                     Available Points: {currentPoints}
//                                 </p>
//                             </div>
//                         }
//                     </div>
//                     <div className="row">
//                         {prizesEnabled &&
//                             <div className="col-4">
//                                 <p>
//                                     Prizes Redeemed: {rewardsRedeemedCount}
//                                 </p>
//                             </div>
//                         }
//                         {badgesEnabled &&
//                             <div className="col-4">
//                                 <p>
//                                     Badges Received: {numberOfBadges}
//                                 </p>
//                             </div>
//                         }
//                     </div>
//                     <div className="row">
//                         <div className="col">
//                             <div className="form-check">
//                                 <label className="form-check-label" htmlFor="advanced">Advanced</label>
//                                 <input value={this.state.advanced} className="form-check-input" id="advanced" name="advanced" type="checkbox" checked={this.state.advanced} onChange={this.handleChange} />
//                             </div>
//                         </div>
//                     </div>
//                     {this.state.advanced &&
//                         <div className="row">
//                             <div className="col" style={{textAlign: "center"}}>
//                                 <button className="btn btn-danger" onClick={()=>this.deleteUser()}>
//                                     <span className="fa fa-trash pre_side_item"/> Permanently Delete User
//                                 </button>
//                             </div>
//                         </div>
//                     }
//                 </div>
//             </div>
//          </div>
//         );
//     }
// }
//
// export default UserInfo


// File: src/components/admin/UserInfo.js

import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { appDatabasePrimaryFunctions } from '../../base';
import {
    convertTimeStampToHumanReadable,
    getURLParameter,
    Toast,
    ErrorMessage,
    WarningMessage
} from '../utils/HelpfulFunction';
import { Navigate } from 'react-router-dom';

// 1) Import your push-notification service function
import { sendImmediateNotificationToTokens, getPushTokenForUser } from '../services/pushNotificationService';

class UserInfo extends Component {
    constructor(props) {
        super(props);
        const uid = getURLParameter('uid');
        const playerPickEnabled = process.env.REACT_APP_HAS_PLAYER_PICK === 'true';
        const checkInEnabled = process.env.REACT_APP_HAS_CHECK_IN === 'true';
        const prizesEnabled = process.env.REACT_APP_HAS_PRIZES === 'true';
        const badgesEnabled = process.env.REACT_APP_HAS_BADGES === 'true';

        this.state = {
            uid: uid,
            loading: true,
            badgesEnabled,
            prizesEnabled,
            checkInEnabled,
            playerPickEnabled,

            // New: store user’s push token, plus fields for custom message
            pushToken: '',
            pushTitle: '',
            pushMessage: '',

            // Advanced UI toggle
            advanced: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.loadUserData();
        const getAdminToken = await this.props.userData.getIdToken();
        this.setState({
            token: getAdminToken
        });
    }

    async loadUserData() {
        const uid = this.state.uid;
        const uidToUsernameRef = await appDatabasePrimaryFunctions
            .ref(`uidToUsername/${uid}`)
            .once('value');
        const uidToUsername = uidToUsernameRef.val() || {};
        const userName = uidToUsername.userName;

        if (!userName) {
            // If no user found, redirect
            this.setState({
                loading: false,
                redirect: true
            });
            return;
        }

        // Build a list of Firebase references to fetch
        const promises = [];
        const definitionsOfPromises = [];

        // Basic user data (including pushToken, if available)
        const userRef = appDatabasePrimaryFunctions.ref(`users/${uid}`).once('value');
        promises.push(userRef);
        definitionsOfPromises.push('users');

        // Conditional references
        const userLevelRef = appDatabasePrimaryFunctions.ref(`userLevel/${uid}`).once('value');
        const userPointsRef = appDatabasePrimaryFunctions.ref(`userPoints/${uid}`).once('value');
        if (this.state.badgesEnabled || this.state.checkInEnabled || this.state.playerPickEnabled) {
            promises.push(userLevelRef, userPointsRef);
            definitionsOfPromises.push('level', 'points');
        }

        const userHistoryRef = appDatabasePrimaryFunctions.ref(`userHistory/${userName}`).once('value');
        if (this.state.checkInEnabled) {
            promises.push(userHistoryRef);
            definitionsOfPromises.push('checkIns');
        }

        // For prizes
        const uniqueRewardUrl = process.env.REACT_APP_REWARD_URL + `redeemed_coupons/user/${uid}`;
        if (this.state.prizesEnabled) {
            // (In your original code, you used 'promises.push(uniqueRewardUrl)' but that’s a URL string,
            // you’d typically fetch that via fetch() if you have an API.)
            // Adjust if needed:
            promises.push(Promise.resolve({ data: [] })); // placeholder
            definitionsOfPromises.push('rewards');
        }

        // For badges
        const userBadgesRef = appDatabasePrimaryFunctions.ref(`userBadges/${uid}`).once('value');
        if (this.state.badgesEnabled) {
            promises.push(userBadgesRef);
            definitionsOfPromises.push('badges');
        }

        const couponEmailRef = appDatabasePrimaryFunctions.ref(`couponEmail/${uid}`).once('value');
        const userNameToEmailRef = appDatabasePrimaryFunctions.ref(`usernameToEmail/${userName}`).once('value');
        promises.push(couponEmailRef, userNameToEmailRef);
        definitionsOfPromises.push('couponEmail', 'userNameToEmail');

        // Resolve all data
        const promisesRun = await Promise.all(promises);

        // Parse data
        let user = {};
        let userLevel = {};
        let userPoints = {};
        let userRewards = {};
        let userBadges = {};
        let couponEmail = '';
        let userNameToEmail = {};
        let userHistory = {};

        for (let i = 0; i < promisesRun.length; i++) {
            const promiseResult = promisesRun[i];
            const promiseName = definitionsOfPromises[i];

            if (promiseName === 'users') {
                user = promiseResult.val() || {};
            } else if (promiseName === 'level') {
                userLevel = promiseResult.val() || {};
            } else if (promiseName === 'points') {
                userPoints = promiseResult.val() || {};
            } else if (promiseName === 'checkIns') {
                userHistory = promiseResult.val() || {};
            } else if (promiseName === 'rewards') {
                // In the original code, `userRewards = promiseName || {};`
                // That looks like a bug. We'll do:
                userRewards = promiseResult || {};
            } else if (promiseName === 'badges') {
                userBadges = promiseResult.val() || {};
            } else if (promiseName === 'couponEmail') {
                couponEmail = promiseResult.val() || '';
            } else if (promiseName === 'userNameToEmail') {
                userNameToEmail = promiseResult.val() || {};
            } else {
                console.log('NOT FOUND');
            }
        }

        const rewardsRedeemedCount = userRewards.data ? userRewards.data.length : 0;
        const email = user.email || userNameToEmail.email || couponEmail || '';
        const signUpTime = user.signUpTime;
        const totalPoints = userLevel.totalPoints || 0;
        const currentPoints = userPoints.points || 0;
        const checkIns = userHistory.checkIns || {};
        const checkInsCount = Object.keys(checkIns).length;
        const numberOfBadges = Object.keys(userBadges).length;
        const pushTokens = await getPushTokenForUser(user.uid);

        this.setState({
            loading: false,
            checkInsCount: checkInsCount,
            currentPoints: currentPoints,
            totalPoints: totalPoints,
            signUpTime: signUpTime,
            email: email,
            userName: userName,
            rewardsRedeemedCount: rewardsRedeemedCount,
            numberOfBadges: numberOfBadges,
            pushToken: pushTokens
        });
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    /**
     * Delete the user (existing logic).
     */
    async deleteUser() {
        const result = await WarningMessage.fire({
            title: 'STOP!',
            text: 'Are you sure you want to do this? This will erase all information about this user. This data will be IRRETRIEVABLE.',
            confirmButtonText: 'YES I WANT TO PERMANENTLY DELETE THIS DATA'
        });
        if (!result || !result.value) return;

        const userResponse = { uidToDelete: this.state.uid };
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/deleteUser`;
        if (process.env.NODE_ENV === 'development') {
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/deleteUser`;
        }

        this.setState({ loading: true });
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.state.token}`
                },
                body: JSON.stringify(userResponse)
            });
            this.setState({ loading: false });

            const value = await res.json();
            if (!value || value.message === 'Invalid Permissions') {
                await ErrorMessage.fire({
                    title: 'Oh uh!',
                    text: 'Auth or request error; logout and log back in'
                });
                return;
            } else if (value.message === 'auth/user-not-found - There is no user record corresponding to the provided identifier.') {
                await ErrorMessage.fire({
                    title: 'Oh uh!',
                    text: 'User not found, check your internet connection and try again. If the problem continues reach out to Sqwad.'
                });
                return;
            }

            // success
            this.setState({ redirect: true });
            await Toast.fire({
                title: 'User Deleted'
            });
        } catch (err) {
            console.error(err);
            await ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Auth or request error; logout and log back in'
            });
            this.setState({ loading: false });
        }
    }

    /**
     * New: Send a push message to this specific user, using their pushToken
     */
    async sendPushToUser() {
        const { pushToken='ab21e42bcde925c62100ae09f34c43a46210b542d5442946b063e616bcc11e57', pushTitle, pushMessage, userName } = this.state;
        if (!pushToken) {
            await ErrorMessage.fire({
                title: 'No Push Token',
                text: `User "${userName}" does not have a push token.`
            });
            return;
        }
        if (!pushMessage.trim()) {
            await ErrorMessage.fire({
                title: 'No Message',
                text: 'Please enter a push message before sending.'
            });
            return;
        }

        console.log(pushToken)

        // Here we call the service function
        try {
            await sendImmediateNotificationToTokens(
                [pushToken],
                pushTitle,
                pushMessage
            );

            // Optionally show a success toast (the service might already do that).
            // Toast.fire({ title: 'Push sent successfully!' });
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const {
            loading,
            email = '',
            checkInsCount = 0,
            currentPoints = 0,
            totalPoints = 0,
            signUpTime = 0,
            userName = '',
            rewardsRedeemedCount = 0,
            numberOfBadges = 0,
            redirect = false,
            playerPickEnabled = false,
            checkInEnabled = false,
            prizesEnabled = false,
            badgesEnabled = false,

            // For sending push messages
            pushTitle,
            pushMessage,

            advanced
        } = this.state;

        if (redirect) {
            return <Navigate to="/usermanagement" />;
        }

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container" style={{ color: 'black', padding: 20 }}>
                        {/* Basic User Info */}
                        <div className="row" style={{ margin: 20 }}>
                            <div className="col" style={{ textAlign: 'center' }}>
                                <p style={{ fontSize: 40 }}>User Info</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>Username: {userName}</p>
                                <p>Email: {email || 'None Found'}</p>
                                <p>
                                    Signed Up:{' '}
                                    {signUpTime ? convertTimeStampToHumanReadable(signUpTime) : 'None Detected'}
                                </p>
                            </div>
                        </div>

                        {/* Extra Stats */}
                        <div className="row">
                            {checkInEnabled && (
                                <div className="col-4">
                                    <p>Check Ins: {checkInsCount}</p>
                                </div>
                            )}
                            {(playerPickEnabled || checkInEnabled || badgesEnabled) && (
                                <div className="col-4">
                                    <p>Total Points: {totalPoints}</p>
                                </div>
                            )}
                            {(playerPickEnabled || checkInEnabled || badgesEnabled) && (
                                <div className="col-4">
                                    <p>Available Points: {currentPoints}</p>
                                </div>
                            )}
                        </div>

                        <div className="row">
                            {prizesEnabled && (
                                <div className="col-4">
                                    <p>Prizes Redeemed: {rewardsRedeemedCount}</p>
                                </div>
                            )}
                            {badgesEnabled && (
                                <div className="col-4">
                                    <p>Badges Received: {numberOfBadges}</p>
                                </div>
                            )}
                        </div>

                        {/* Toggle for advanced actions (e.g. delete user) */}
                        <div className="row">
                            <div className="col">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="advanced">
                                        Advanced
                                    </label>
                                    <input
                                        value={advanced}
                                        className="form-check-input"
                                        id="advanced"
                                        name="advanced"
                                        type="checkbox"
                                        checked={advanced}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Advanced controls */}
                        {advanced && (
                            <div className="row">
                                <div className="col" style={{ textAlign: 'center' }}>
                                    <button className="btn btn-danger" onClick={() => this.deleteUser()}>
                                        <span className="fa fa-trash pre_side_item" /> Permanently Delete User
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Send a direct push message to this user */}
                        {/*<div className="row" style={{ marginTop: 30 }}>*/}
                        {/*    <div className="col-12" style={{ textAlign: 'center' }}>*/}
                        {/*        <h4>Send a Push Notification to {userName}</h4>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row" style={{ marginTop: 10 }}>*/}
                        {/*    <div className="col-md-6 offset-md-3">*/}
                        {/*        <div className="form-group">*/}
                        {/*            <label htmlFor="pushTitle">Title (optional):</label>*/}
                        {/*            <input*/}
                        {/*                type="text"*/}
                        {/*                className="form-control"*/}
                        {/*                id="pushTitle"*/}
                        {/*                name="pushTitle"*/}
                        {/*                value={pushTitle}*/}
                        {/*                onChange={this.handleChange}*/}
                        {/*                placeholder="e.g. Hello from Admin"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <div className="form-group" style={{ marginTop: 10 }}>*/}
                        {/*            <label htmlFor="pushMessage">Message:</label>*/}
                        {/*            <textarea*/}
                        {/*                className="form-control"*/}
                        {/*                id="pushMessage"*/}
                        {/*                name="pushMessage"*/}
                        {/*                value={pushMessage}*/}
                        {/*                onChange={this.handleChange}*/}
                        {/*                placeholder="Enter your notification message here..."*/}
                        {/*                rows="3"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        <button*/}
                        {/*            className="btn btn-primary"*/}
                        {/*            style={{ marginTop: 10 }}*/}
                        {/*            onClick={() => this.sendPushToUser()}*/}
                        {/*        >*/}
                        {/*            Send Push Notification*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default UserInfo;
